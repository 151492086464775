<template>
	<div
		v-if="options.length > 0"
		class="row"
		:class="containerClass">
		<div class="col">
			<div class="row no-gutters">
				<div class="col">
					<b-row
						no-gutters
						class="mb-3">
						<b-col>
							<p
								class="m-0 font-weight-bolder title"
								:class="titleClass">
								{{ title }}
							</p>
						</b-col>
					</b-row>
					<b-row>
						<b-col
							class="d-flex justify-content-center flex-wrap"
							style="gap: 0.5rem">
							<div
								v-for="(option, index) in options"
								:key="index">
								<div
									v-if="inVariant(['text'])"
									:class="selectedOption === option.value ? 'config-color-active' : 'config-color'"
									:style="configurationBtnStyles(option)"
									class="btn config-btn text-decoration-none rounded-0 p-1 mb-2 px-3 pointer"
									@click="selectedOption = option.value">
									<p class="font-weight-bold mb-0 p-1 h6 text-medium">
										{{ option.text }}
									</p>
								</div>
							</div>
						</b-col>
					</b-row>
				</div>
			</div>
			<!-- Slot to add more rows -->
			<slot />
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import Color from '@/views/LandingPages2/mixins/Color';

export default {
	name: 'OptionSelect',
	mixins: [WindowSizes, Color],
	props: {
		title: {
			type: String,
			default: '',
		},
		options: {
			type: Array,
			default: () => [],
		},
		selectFirst: {
			type: Boolean,
			default: true,
		},
		variant: {
			type: String,
			default: 'text',
			validator(value) {
				return ['text'].includes(value);
			},
		},
		// The option to be selected, if exists in options values
		selectedOptionImport: {
			type: [String, undefined],
			default: undefined,
		},
		containerClass: {
			type: String,
			default: '',
		},
		titleClass: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			selectedOption: this.selectedOptionImport,
		};
	},
	watch: {
		selectedOption() {
			this.$emit('update:selectedOption', this.selectedOption);
		},
		options(newOpts) {
			if (newOpts.findIndex((opt) => opt.value === this.selectedOption) === -1) {
				this.selectFirstItem();
			}
		},
		selectedOptionImport() {
			this.selectedOption = this.selectedOptionImport;
		},
	},
	mounted() {
		this.selectFirstItem();
	},
	methods: {
		inVariant(variants) {
			return variants.includes(this.variant);
		},
		selectFirstItem() {
			if (this.selectFirst && this.options.length > 0) {
				this.selectedOption = this.options[0].value;
			}
		},
		configurationBtnStyles(option) {
			const isSelected = this.selectedOption === option.value;

			const styles = {
				border: `2px solid ${option.color ?? this.highlightColor};`,
			};

			styles.width = '100%';

			if (isSelected) {
				styles['background-color'] = option.color ?? this.highlightColor;
				styles.color = '#FFFFFF';
			} else {
				styles['--config-color'] = '#FFFFFF';
				styles['--product-primary-color'] = option.color ?? this.highlightColor;
			}

			return Object.entries(styles)
				.map(([prop, value]) => `${prop}: ${value};`)
				.join(' ');
		},
	},
};
</script>
<style scoped>
.config-btn > p {
	line-height: 1.2 !important;
}

.config-color-active > *, .config-color-active:hover {
	color: #FFFFFF !important;
}

.config-color {
	color: var(--product-primary-color);
}

.config-color:hover > * {
	color: var(--config-color) !important;
}

.config-color:hover {
	background-color: var(--product-primary-color);
}

.title {
	color: #737272;
}
</style>
