<template>
	<section
		:class="['xs'].includes(windowWidth) ? 'px-3' : 'px-5'"
		class="w-100 h-100 row no-gutters text-dark py-5"
		:style="background">
		<div class="col h-100 d-flex justify-content-center">
			<div class="container h-100">
				<div class="row h-100 no-gutters">
					<div class="col-12 h-100">
						<slot />
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'PageSection',
	mixins: [WindowSizes],
	props: {
		backgroundStyle: {
			type: String,
			default: '',
		},
	},
	computed: {
		background() {
			return this.backgroundStyle ? this.backgroundStyle : 'background-color: #FFFFFF; !important';
		},
	},
};
</script>
