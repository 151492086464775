<template>
	<div
		class="card rounded-0 shadow border-0 mb-0 p-0 mt-4 h-100"
		style="min-width: 180px"
		:style="colorsStyles">
		<div class="card-body p-4 m-0 h-100">
			<div v-if="mainProductLoading">
				<is-loading
					:loading="mainProductLoading"
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')" />
			</div>
			<b-row v-else>
				<b-col
					:cols="horizontalView ? '6' : '12'"
					class="px-3">
					<image-carousel
						:images="imgCarousel"
						:group-to-show="getGroupToShow"
						:enable-zoom="true"
						:highlight-color="highlightColor"
						:modal-id="modalId" />
				</b-col>

				<b-col :cols="horizontalView ? '6' : '12'">
					<b-row class="h-100">
						<b-col
							cols="12"
							:class="horizontalView ? 'd-flex flex-column align-items-start justify-content-end' : ''">
							<!-- Product name / price -->
							<div>
								<h2
									class="section-header p-0"
									:class="computedSubtitle.length > 0 ? 'mb-0' : ''"
									:style="productNameStyle">
									{{ computedTitle }}
								</h2>
							</div>
							<div>
								<h2
									v-if="computedSubtitle.length > 0"
									class="p-0 text-uppercase"
									style="font-size: 2.5rem !important; max-width: 310px">
									{{ computedSubtitle }}
								</h2>
							</div>
							<!-- <p
								class="h4 font-weight-bold text-center price">
								{{ mainProductPrice }}
							</p>
							<div
								v-if="mainHasExchange"
								class="mx-auto exchange-text text-center medium d-flex align-items-end">
								{{ mainExchangePrice }}*
							</div>
							<div
								v-if="mainHasExchange"
								class="mx-auto exchange-text text-left small d-flex align-items-end">
								{{ translate('exchange_disclaimer') }}
							</div> -->
							<p class="text-muted h6 font-weight-bold text-center">
								{{ mainProductData.presentation }}
							</p>
							<div class="w-100 border-bottom my-4 px-5" />
						</b-col>

						<b-col
							cols="12">
							<div
								class="d-flex flex-column h-100"
								:class="{
									'align-items-start': horizontalView,
									'justify-content-start': horizontalView,
									'justify-content-between': !horizontalView,
								}">
								<div>
									<option-select
										:container-class="horizontalView ? 'mb-3' : 'my-3'"
										:title-class="horizontalView ? 'text-left': 'text-center'"
										:highlight-color="highlightColor"
										:title="translate('flavor')"
										:options="getFlavor"
										:selected-option.sync="selectedProduct.flavor" />
									<option-select
										:container-class="horizontalView ? 'mb-3' : 'my-3'"
										:title-class="horizontalView ? 'text-left': 'text-center'"
										:highlight-color="highlightColor"
										:title="translate('material_type')"
										:options="getMaterialType"
										:selected-option.sync="selectedProduct.material_type" />
									<option-select
										:container-class="horizontalView ? 'mb-3' : 'my-3'"
										:title-class="horizontalView ? 'text-left': 'text-center'"
										:highlight-color="highlightColor"
										:title="translate('presentations')"
										:options="getPresentations"
										:selected-option.sync="selectedProduct.presentations" />
									<!-- Product out of stock -->
									<b-row
										v-if="typeof outOfStockProducts[mainProductData.code_name] !== 'undefined'">
										<b-col class="align-self-center w-auto">
											<b-alert
												show
												variant="warning"
												class="mb-0">
												{{ translate(`${outOfStockProducts[mainProductData.code_name]}_out_of_stock`) }}
											</b-alert>
										</b-col>
									</b-row>
								</div>
								<!-- Product qty/add btn -->
								<b-row
									align-h="center"
									align-v="center"
									class="mt-2 mb-3">
									<b-col class="col-auto">
										<number-input-spinner
											v-model="selectedProduct.quantity"
											:mouse-down-speed="500"
											:min="MIN_QTY"
											:max="MAX_QTY"
											:button-class="'vnis__button bg-primary-alt'"
											:input-class="'vnis__input vnis-custom-input-width'"
											:integer-only="true"
											class="vnis-smaller" />
									</b-col>
									<b-col class="col-auto">
										<div
											:class="{
												disabled: addToCartDisabled,
												'rounded-0': !roundedButton,
											}"
											:style="makeHighlightStyle('background-color')"
											class="btn py-2 text-white pointer custom-btn add-to-cart-button custom-primary-btn px-4 text-medium text-nowrap"
											style="font-weight: 600;"
											@click="addProduct(getProductSku, getProductOptions(getProductSku));">
											<template v-if="addProductsLoading">
												<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
											</template>
											<template v-else>
												{{ translate('add_to_cart') }}
											</template>
										</div>
									</b-col>
								</b-row>
								<div
									v-if="['LT'].includes(mainProductData.sku) && !['NZ'].includes(country)"
									class="row">
									<div
										:class="{
											'd-flex justify-content-center': ['xs', 'sm', 'md'].includes(windowWidth),
										}"
										class="col mb-3">
										<div
											:class="addProductsLoading ? 'disabled' : ''"
											class="btn btn-primary p-2 pointer custom-btn custom-primary-btn add-all-options-btn"
											style="font-weight: 600; font-size: 1rem; background-color: #6aa50a; border-width: 0; height: 50px; display: flex; justify-content: center; align-items: center"
											:style="{ width: ['xs'].includes(windowWidth) ? '100%' : '285px' }"
											@click="addToCartDisabled ? null : addProduct(getManyProductSku())">
											<template v-if="addProductsLoading">
												<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
											</template>
											<template v-else>
												{{ translate('get_all_of_them') }}
											</template>
										</div>
									</div>
								</div>
							</div>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import cookie from 'vue-cookie';
import NumberInputSpinner from 'vue-number-input-spinner';
import WindowSizes from '@/mixins/WindowSizes';
import Color from '../mixins/Color';
import Products from '@/util/Products';
import { NON_AUTH_HIDDEN_PRODUCTS, PRODUCT_DEFAULT_PRICE, PRODUCT_OVERRIDE_PRICE } from '@/settings/Products';
import { MAX_QTY, MIN_QTY } from '@/settings/Cart';
import ImageCarousel from '@/components/CompactImageCarousel';
import Cart from '@/util/Cart';
import EventBus from '@/util/eventBus';
import { trackEvent } from '@/util/GoogleTagManager';
import isLoading from '@/components/Loading';
import {
	Store, Products as ProductsTranslations,
} from '@/translations';
import OptionSelect from './OptionSelect';
import PublicStore from '@/mixins/PublicStore';

export default {
	name: 'ProductCard',
	messages: [Store, ProductsTranslations],
	components: {
		isLoading,
		ImageCarousel,
		NumberInputSpinner,
		OptionSelect,
	},
	mixins: [Color, WindowSizes, PublicStore],
	props: {
		product: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		modalId: {
			type: String,
			required: true,
		},
		horizontal: {
			type: Boolean,
			default: false,
		},
		roundedButton: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			mainProduct: new Products(),
			addProducts: new Cart(),
			alert: new this.$Alert(),
			selectedProduct: {
				quantity: MIN_QTY,
				flavor: '',
				material_type: '',
				presentations: '',
			},
			MIN_QTY,
			MAX_QTY,
			outOfStockProducts: {},
		};
	},
	computed: {
		addToCartDisabled() {
			return this.addProductsLoading || this.$user.details().active_multi_payment;
		},
		colorsStyles() {
			return {
				'--product-primary-color': this.highlightColor,
			};
		},
		mainProductData() {
			try {
				const { data } = this.mainProduct.data.response.data;
				const response = {
					sku: data.attributes.sku,
					code_name: data.attributes.code_name,
					price: this.getProductPrice(data.attributes),
					image: data.attributes.image,
					presentation: this.translate(`${data.attributes.code_name}_presentation`),
					children: data.attributes.children,
					thumbnail: data.attributes.thumbnail,
					exchange: data.attributes.exchange,
					exchange_price: data.attributes.exchange_price,
					tabsInfo: {
						details: this.translate(`${data.attributes.code_name}_description`),
						// more_information: '',
					},
				};
				return response;
			} catch (error) {
				return {};
			}
		},
		mainProductPrice() {
			try {
				if (Object.keys(this.mainProductData).length > 0) {
					if (PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.price) {
						return PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.price;
					}
					return this.mainProductData.children[Object.keys(this.getProductSku)[0]].price;
				}
				return this.mainProductData.price;
			} catch (e) {
				return this.mainProductData.price;
			}
		},
		getProductSku() {
			try {
				let sku = [this.mainProductData.sku];
				if (this.selectedProduct.flavor) {
					sku.push(this.selectedProduct.flavor);
				}
				if (this.selectedProduct.material_type) {
					sku.push(this.selectedProduct.material_type);
				}
				if (this.selectedProduct.presentations) {
					sku.push(this.selectedProduct.presentations);
				}
				sku = sku.join('-');
				return { [sku]: this.selectedProduct.quantity };
			} catch (error) {
				return [];
			}
		},
		mainProductLoading() {
			return !!this.mainProduct.data.loading;
		},
		addProductsLoading() {
			return !!this.addProducts.data.loading;
		},
		productNameStyle() {
			const styles = [
				`color: ${this.titleHighlightColor} !important;`,
				this.computedSubtitle.length > 0 ? 'font-size: 2.5rem !important;' : 'font-size: 1.6rem !important;',
				this.computedSubtitle.length > 0 ? '' : 'text-align: center;',
			];

			return styles.join(' ');
		},
		mainHasExchange() {
			try {
				if (Object.keys(this.mainProductData).length > 0) {
					if (PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.exchangePrice) {
						return !!PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.exchangePrice;
					}
					return Object.keys(this.mainProductData.children).length ? this.mainProductData.children[Object.keys(this.getProductSku)[0]].exchange : this.mainProductData.exchange;
				}
				return false;
			} catch (e) {
				return false;
			}
		},
		mainExchangePrice() {
			try {
				if (this.mainHasExchange) {
					if (PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.exchangePrice) {
						return PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.exchangePrice;
					}
					return Object.keys(this.mainProductData.children).length ? this.mainProductData.children[Object.keys(this.getProductSku)[0]].exchange_price : this.mainProductData.exchange_price;
				}
				return '';
			} catch (e) {
				return '';
			}
		},
		getGroupToShow() {
			return Object.keys(this.getProductSku)[0];
		},
		imgCarousel() {
			try {
				const { data } = this.mainProduct.data.response.data;
				return data.attributes.extra_images;
			} catch (error) {
				return {};
			}
		},
		getFlavor() {
			try {
				const { data } = this.mainProduct.data.response.data;
				const availableFlavors = Object.keys(data.attributes.configurations.flavor);
				return availableFlavors.map((value) => ({ value, text: this.translate(`${value}_flavor`) }));
			} catch (error) {
				return [];
			}
		},
		getMaterialType() {
			try {
				const { data } = this.mainProduct.data.response.data;
				const availableMaterialType = Object.keys(data.attributes.configurations.material_type);
				return availableMaterialType.map((value) => ({ value, text: this.translate(`${value}_material_type`) }));
			} catch (error) {
				return [];
			}
		},
		getPresentations() {
			try {
				const { data } = this.mainProduct.data.response.data;
				const availablePresentations = Object.keys(data.attributes.configurations.presentations);
				return availablePresentations.map((value) => ({ value, text: this.translate(`${value}_presentations`), color: this.optionColors[value] }));
			} catch (error) {
				return [];
			}
		},
		optionColors() {
			return {
				BR: '#df713d',
				ZL: '#66b2e8',
				BY: '#794c99',
			};
		},
		titleHighlightColor() {
			const option = this.getGroupToShow.split('-').reverse()[0] ?? '';
			return this.optionColors[option] ?? this.highlightColor;
		},
		horizontalView() {
			return this.horizontal && !['xs', 'sm', 'md'].includes(this.windowWidth);
		},
		computedTitle() {
			const data = this.mainProductData;
			let { code_name: title } = data;
			const customTranslateKey = this.customTranslateKey(data.sku);
			if (typeof customTranslateKey !== 'undefined' && customTranslateKey.title) {
				title = customTranslateKey.title;
			}
			return this.translate(title);
		},
		computedSubtitle() {
			const data = this.mainProductData;
			const customTranslateKey = this.customTranslateKey(data.sku);
			if (typeof customTranslateKey !== 'undefined' && customTranslateKey.subtitle) {
				return this.translate(customTranslateKey.subtitle);
			}
			return '';
		},
	},
	watch: {
		async country() {
			this.initProductInfo();
		},
	},
	mounted() {
		if (!this.showPublicStore) {
			this.$router.replace({ name: 'Store' }).catch(() => {});
			return;
		}
		this.initProductInfo();
	},
	methods: {
		initProductInfo() {
			const productName = this.product;
			return this.mainProduct.getProductDetails(productName).then((response) => {
				const { sku } = response.attributes;

				if (!this.$user.auth() && NON_AUTH_HIDDEN_PRODUCTS.includes(sku)) {
					this.$router.push({ name: 'Store' });
				}

				[this.selectedProduct.flavor] = [this.getFlavor[0]?.value];
				[this.selectedProduct.material_type] = [this.getMaterialType[0]?.value];
				[this.selectedProduct.presentations] = [this.getPresentations[0]?.value];
			}).catch((error) => {
				if (this.NOT_FOUND.includes(error.status)) this.$router.push({ name: 'Store' });
			});
		},
		getProductPrice(product, priceKey = 'price') {
			const { price, sku } = product;

			if (PRODUCT_OVERRIDE_PRICE[sku]?.[priceKey]) {
				return PRODUCT_OVERRIDE_PRICE[sku][priceKey];
			}

			if (price !== '$0.00 USD') {
				return price;
			}

			if (PRODUCT_DEFAULT_PRICE[sku]?.[priceKey]) {
				return PRODUCT_DEFAULT_PRICE[sku][priceKey];
			}

			return price;
		},
		async addProduct(products) {
			if (!this.addToCartDisabled) {
				try {
					await this.addProducts.addCartProducts(cookie.get('cart_id'), { products, sponsor_id: this.$replicated.replicatedSponsor() });
					trackEvent(this.$gtm, 'productQtyUpdated'); // TODO: track product quantity and sku
					EventBus.$emit('updateLayoutStoreTotals');
				} catch (e) {
					if (typeof this.addProducts.errors.errors.products !== 'undefined') {
						let response = '';
						this.addProducts.errors.errors.products.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 4000 });
					} else {
						this.alert.toast('error', this.translate('default_error_message'));
					}
				}
			}
		},
		getProductOptions(products) {
			let image = this.mainProductData.thumbnail;
			if (Object.keys(this.mainProductData.children).length) {
				const { children } = this.mainProductData;
				Object.keys(products).forEach((sku) => { image = children[sku].thumbnail; });
			}
			const options = { image };
			return options;
		},
		customTranslateKey(sku) {
			switch (sku) {
			case 'LT': {
				if (this.selectedProduct.presentations === 'BR') {
					return { title: 'bran', subtitle: 'reimagined' };
				}
				if (this.selectedProduct.presentations === 'ZL') {
					return { title: 'zlem', subtitle: 'sleep_and_slim' };
				}
				if (this.selectedProduct.presentations === 'BY') {
					return { title: 'byom', subtitle: 'remastered' };
				}
				return undefined;
			}
			default: return undefined;
			}
		},
		getManyProductSku() {
			const data = this.mainProductData;
			const sku = {};
			Object.keys(data?.children ?? {}).forEach((childSku) => {
				sku[childSku] = 1;
			});
			return sku;
		},
	},
};
</script>
<style scoped>
.custom-btn.custom-primary-btn {
	min-width: 150px !important;
	border-radius: 25px;
}
.price {
	color: #737272;
}
</style>
<style>
#theme .vnis-custom-input-width {
	width: 40px;
}
</style>
